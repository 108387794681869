import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Root as App} from './components';
import { __ROOT_ROUTE__ } from './routes';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import allReducers from './reducer/reducer.js';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WF9Q76F'
}

TagManager.initialize(tagManagerArgs);
const store = createStore(allReducers);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);