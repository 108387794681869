import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Slider from "react-slick";
import { settings4 } from '../../constants/slider';
import './style.scss';

import whiteBG from '../../assets/img/qbgWhite.png';
import greenBG from '../../assets/img/qbgGreen.png';
import blueBG from '../../assets/img/qbgBlue.png';

import whiteBGmob from '../../assets/img/mobQ1.png';
import greenBGmob from '../../assets/img/mobQ2.png';
import blueBGmob from '../../assets/img/mobQ3.png';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const View = React.memo(() => {

    const [width, height] = useWindowSize();
    const color = useSelector(state => state.colorSite);

    var url = (color == 'white') ? (width < 560) ? whiteBGmob:whiteBG : (color == 'green')? (width < 560) ? greenBGmob:greenBG : (width < 560) ? blueBGmob:blueBG;

    return (

        <section className='Quotes' style={{backgroundImage: 'url(' + url + ')',backgroundSize: (width < 560) ? '100% 100%':'cover', backgroundPosition: 'center center'}} >
            <div className="wrapper">
                <Slider className="quoteSlider" {...settings4}>

                    <div className="quoteSlide">
                        <p className='text'>Digitum is our reliable partner in development and governance of gamification platforms for various customer segments, included affluent clients and young audiences, providing us with creative and customized solutions that engage customers and drive their performance</p>
                        <p className='name'>Alexey Puzniak</p>
                        <p className='position'>Director of The Product Department for Private Clients, Marketing and Customer Relations Management of Raiffeisen Bank Aval</p>
                    </div>

                    <div className="quoteSlide">
                        <p className='text'>It’s a pleasure to work with Digitum. Together we improve our prospect’s online experience and digital engagement across their journey. With a hardworking attitude and bright ideas, Digitum's team contributes to our projects by bringing fresh digital approaches. They help me to deliver on time, creatively and within budget. I can recommend Digitum as a good sparring partner in digital marketing solutions.</p>
                        <p className='name'>Nicola Meinders</p>
                        <p className='position'>Global Marketing Director<br/>Jonckers</p>
                    </div>

                    <div className="quoteSlide">
                        <p className='text'>At UkrSibbank BNP Paribas we have successfully implemented EasyGrow solutions as nationwide motivational tool for our employees. It was customized upon our requests and support in development and governance of gamification platform was promptly provided. We can recommend EasyGrow as creative and efficient IT solution that stimulate sales and drive employees performance.</p>
                        <p className='name'>Inna Antoniuk</p>
                        <p className='position'>Leading Specialist on Development and Support of Non-Financial Motivation Ukrsibbank Bnp Paribas Group.</p>
                    </div>

                </Slider>
            </div>
        </section>
    );
});

export { View };