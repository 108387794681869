import React from 'react';
import logoImg from '../../../assets/img/Logo_Digitum.svg';
import icon1 from '../../../assets/img/footerSVG1.svg';
import icon2 from '../../../assets/img/footerSVG2.svg';
import icon3 from '../../../assets/img/footerSVG3.svg';

const View = React.memo(() => {

	return (
		<footer style={{height: 'auto', maxHeight: 'none'}}>
			<div className="wrapper">
				<div className="socials" style={{ justifyContent: 'center' }}>
					<a style={{ marginLeft: '0px', margin: '16px' }} href="https://www.linkedin.com/company/digitum-marketing/posts/?feedView=all" target="_blank">
						<img src={ icon1 } alt="icon" />
					</a>
					<a style={{ marginLeft: '0px', margin: '16px' }} href="https://t.me/+31627305673" target="_blank">
						<img src={ icon2 } alt="icon" />
					</a>
					<a style={{ marginLeft: '0px', margin: '16px' }} href="https://wa.me/+31627305673" target="_blank">
						<img src={ icon3 } alt="icon" />
					</a>
				</div>
				<p style={{
					fontFamily: 'Futura PT',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '32px',
					lineHeight: '100%',
					textAlign: 'center',					
					color: '#FFFFFF',
					marginTop: '16px',
					marginBottom: '50px'
				}}>
					Feel free to text or call
				</p>
				<p style={{
					fontFamily: '"Open Sans", sans-serif',
					fontWeight: '300',
					lineHeight: '1.55',
					fontSize: '18px',
					textAlign: 'center',
					color: '#fff'
				}}>
					Tel/Whatsapp: <a href='tel:+31627305673'
					style={{
						color: '#fff',
						textDecoration: 'none'
					}}
					>+31627305673</a>
				</p>
				<p style={{
					fontFamily: '"Open Sans", sans-serif',
					fontWeight: '300',
					lineHeight: '1.55',
					fontSize: '18px',
					textAlign: 'center',
					color: '#fff'
				}}>
					Email: <a 
					style={{
						color: '#fff',
						textDecoration: 'none'
					}}
					href='mailto:info@digitum.nl'>info@digitum.nl</a>
				</p>
				<div className="info" style={{paddingBottom: '36px'}}>
					<p style={{cursor: 'pointer'}} onClick={() => {window.open('https://digitum.agency', '_blank')}}>2022 ©  Digitum</p>

					<p style={{alignItems: 'flex-end'}}>EASYGROW Powered by <img src={logoImg} style={{cursor: 'pointer'}} onClick={() => {window.open('https://digitum.agency', '_blank')}} alt="logo"/></p>
				</div>
        	</div>
		</footer>
		);
});

export { View };