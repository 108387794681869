export const settings = {
    dots: false,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export const settings2 = {
    slidesToShow: 4,
    dots: false,
    arrows: true,
    autoplay: true,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                arrows: true,
                slidesToScroll: 1,
            }
        }
    ]
}

export const settings3 = {
    slidesToShow: 3,
    dots: false,
    arrows: true,
    autoplay: false,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 876,
            settings: {
                slidesToShow: 1,
                arrows: true,
                slidesToScroll: 1,
            }
        }
    ]
}

export const settings4 = {
    dots: false,
    autoplay: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
};