import React, {useEffect} from 'react';

import img1 from '../../assets/img/ico01.png';
import img2 from '../../assets/img/ico02.png';
import img3 from '../../assets/img/ico03.png';
import logo from '../../assets/img/digitum2.svg';

import img1b from '../../assets/img/ico01blue.png';
import img2b from '../../assets/img/ico02blue.png';
import img3b from '../../assets/img/ico03blue.png';
import logob from '../../assets/img/digitum4.svg';

import img1g from '../../assets/img/ico01green.png';
import img2g from '../../assets/img/ico02green.png';
import img3g from '../../assets/img/ico03green.png';

import { useSelector } from 'react-redux';

import data from '../../assets/data.json';

const View = React.memo(() => {
    useEffect(() => {
        
    });
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    var icono1 = (color == 'white') ? img1 : (color == 'green')? img1g : img1b;
    var icono2 = (color == 'white') ? img2 : (color == 'green')? img2g : img2b;
    var icono3 = (color == 'white') ? img3 : (color == 'green')? img3g : img3b;
    var icono4 = (color == 'white') ? logo : (color == 'green')? logob : logob;
	return (
		<section id="about_us" className="block09" style={{backgroundColor: '#1C2238'}}>
            <div className="wrapper">
                <h2 style={{
                    color: '#FFFFFF'
                    // (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'
                }}>{data.lang[lang - 1].block9[0]}</h2>
                <h3 
                style={{
                    color: '#FFFFFF'
                }}
                >{data.lang[lang - 1].block9[1]}</h3>
                <div className="list_solutions">
                    <div className="solution">
                        <img src={img1} alt="icon"/>
                        <p style={{color: '#FFFFFF'}}>{data.lang[lang - 1].block9[2]}</p>
                    </div>
                    <div className="solution">
                        <img src={img2} alt="icon"/>
                        <p style={{color: '#FFFFFF'}}>{data.lang[lang - 1].block9[3]}</p>
                    </div>
                    <div className="solution">
                        <img src={img3} alt="icon"/>
                        <p style={{color: '#FFFFFF'}}>{data.lang[lang - 1].block9[4]}</p>
                    </div>
                </div>
                <img className="pre_logo" style={{display: 'block', margin: 'auto', marginTop: '100px'}} src={logob} alt="logo"/>
                <p style={{color: '#FFFFFF'}}>{data.lang[lang - 1].block9[5]} <a href="https://digitum.nl" style={{
                    color: '#FFFFFF'
                    // color: (color == 'white') ? '#000000' : (color == 'green')? '#ffffff' : '#000000'
                }} target="_blank">digitum.nl</a></p>
            </div>
        </section>
        );
});

export { View };