import React from "react";
import { useDispatch } from 'react-redux';
import { setColorSite } from '../../../actions/actions';

const View = React.memo( () => {
    const dispatch = useDispatch();
    return(
        <svg onClick={() => dispatch(setColorSite('blue'))} id='blue_tilo' width="164" height="111" viewBox="0 0 164 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='blue_left_wing' d="M1.27576 37.3109C-0.0748626 39.7278 7.50363 41.7017 13.3094 40.9066C12.1978 41.9311 7.86639 42.4472 8.25153 43.8079C8.63666 45.1687 12.0641 45.464 14.5209 44.7124C16.8837 43.9682 18.3546 43.1531 20.3143 41.6962C18.3532 43.3831 15.155 45.9216 16.4338 46.6491C17.7127 47.3766 21.6563 46.114 26.5191 40.8423C26.1033 41.9503 24.8118 43.8977 25.9259 44.8321C27.04 45.7665 29.7502 42.062 30.225 39.6045C31.2573 33.8978 25.7497 29.1744 20.2639 31.1656C17.4435 32.1927 14.7165 33.4814 11.9491 34.628C6.92781 36.6992 1.90979 35.6819 1.27576 37.3109Z" fill="url(#paint3_linear)"/>
            <path id='blue_right_wing' d="M161.829 37.2618C163.384 39.5526 156.005 42.1758 150.152 41.8869C151.348 42.8112 155.708 42.9499 155.442 44.3389C155.177 45.728 151.788 46.3192 149.275 45.7833C146.857 45.2467 145.321 44.5622 143.242 43.2806C145.342 44.7912 148.748 47.043 147.537 47.8786C146.326 48.7142 142.288 47.7981 136.986 42.9677C137.497 44.0354 138.952 45.8635 137.923 46.891C136.894 47.9184 133.873 44.4628 133.187 42.0556C131.664 36.4599 136.742 31.277 142.38 32.7852C145.278 33.564 148.107 34.6116 150.963 35.514C156.145 37.1422 161.056 35.6939 161.829 37.2618Z" fill="url(#paint4_linear)"/>
            <ellipse cx="82.0211" cy="55.5" rx="55" ry="55.5" fill="url(#paint5_linear)" color="#009DFA"/>
            <path fill-rule="evenodd" style={{transform: 'scale(1.5)', transformOrigin: '12px 35px'}} clip-rule="evenodd" d="M55.7632 32H56.9652C58.8775 32 60.5532 32.9805 61.4852 34.4506C66.3596 35.2119 70.4037 38.3743 72.2814 42.6443C70.4841 48.2443 65.0893 52.3107 58.7135 52.3107C57.4393 52.3107 56.2043 52.1483 55.0294 51.8437C57.4259 53.6292 60.4275 54.6909 63.6854 54.6909C67.249 54.6909 70.5062 53.4205 73 51.322C71.7149 56.3804 67.5388 60.338 62.2648 61.4587V64H61.0629C59.1446 64 57.4645 63.0134 56.5342 61.5358C49.9657 60.3259 45 54.7376 45 48.0264C45 43.8813 46.8943 40.1646 49.8906 37.6433L49.8906 37.6433C51.5538 36.2437 53.5565 35.2125 55.7632 34.6809V32Z" fill="#101527"/>
            {/* <path d="M92.9248 74.5989C88.6137 77.0952 83.6018 78.095 78.6664 77.4434C73.731 76.7917 69.1478 74.525 65.6278 70.9947C62.1079 67.4645 59.8477 62.868 59.1979 57.9181C58.5482 52.9683 59.5451 47.9418 62.0341 43.6181C64.5231 39.2944 68.3651 35.9153 72.9642 34.0047C77.5633 32.0941 82.6625 31.7589 87.4709 33.0511C92.2793 34.3433 96.5282 37.1906 99.5586 41.1514C102.589 45.1123 104.232 49.9653 104.232 54.9579L81.6181 54.9579L92.9248 74.5989Z" fill="#101527"/> */}
            <defs>
                <linearGradient id="paint3_linear" x1="4.83702" y1="30.9203" x2="28.1927" y2="43.9718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFD600"/>
                    <stop offset="1" stopColor="#E7C200"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="157.727" y1="31.2039" x2="135.59" y2="46.2304" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFD600"/>
                    <stop offset="1" stopColor="#E7C200"/>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="51.5211" y1="22" x2="115.521" y2="105" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#009DFA"/>
                    <stop offset="1" stopColor="#007DC7"/>
                </linearGradient>
            </defs>
        </svg>
    );
});

export { View };