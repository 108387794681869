import React from 'react';
import poster from './../../assets/img/hq720.webp';
import video from './../../assets/EasyGrow_Case_Study.mp4';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';
import data from '../../assets/data.json';

const View = React.memo(() => {
    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
	return (
		<section className="block03">
            <div className="wrapper">

                <p style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '150%',
                    textAlign: 'center',
                    color: '#101527',                   
                    opacity: '0.7',
                    marginTop: '24px'
                }}>EASYGROW by Digitum</p>

                <h3 style={{
                    fontFamily: "'Futura PT'",
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '28px',
                    lineHeight: '100%',
                    textAlign: 'center',
                    color: '#101527',
                    marginBottom: '24px'                   
                }}>How gamification can boost business</h3>

                <div style={{position: "relative"}}>
                    <video style={{margin: '0px', padding: '0px'}} preload="auto" poster={poster} controls>
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
                {/* <button id='feedbackOpen' style={{
                    boxShadow: (color == 'white') ? '0px 13.502px 32.2548px rgba(184, 191, 210, 0.6)' : (color == 'green')? 'none' : 'none',
                }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_im_interested"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block3}</button> */}
            </div>
        </section>
        );
});

export { View };