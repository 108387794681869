import React, {useEffect} from 'react';

import Slider from "react-slick";
import { settings3 } from '../../constants/slider';

import './style.scss';

import box1 from '../../assets/img/Box1.png';
import box2 from '../../assets/img/Box2.png';
import box3 from '../../assets/img/Box3.png';
import box4 from '../../assets/img/Box4.png';
import box5 from '../../assets/img/Box5.png';
import box6 from '../../assets/img/Box6.png';
import box7 from '../../assets/img/Box7.png';

import DC1 from '../../assets/img/imgs02.png';
import DC2 from '../../assets/img/imgs03.png';
import DC3 from '../../assets/img/imgs01.png';
import MDC1 from '../../assets/img/case1.png';
import MDC2 from '../../assets/img/case2.png';
import MDC3 from '../../assets/img/case3.png';

import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI, setSuccessModal, setSuccessModalNum } from '../../actions/actions';

import data from '../../assets/data.json';

const View = React.memo(() => {
    useEffect(() => {
    });
    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
	return (
		<section id="our_cases" className="block08">
            <div className="wrapper">
                <h2 id="block08Translate1" style={{
                    color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'
                }}>{data.lang[lang - 1].block8[0]}</h2>
                <Slider className="imgs" {...settings3}>
                    <img src={box1} alt="box" />
                    <img src={box2} alt="box" />
                    <img src={box3} alt="box" />
                    <img src={box4} alt="box" />
                    <img src={box5} alt="box" />
                    <img src={box6} alt="box" />
                    <img src={box7} alt="box" />
                </Slider>
                {/* <div className="imgs"> */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(1));}} */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(2));}} */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(3));}} */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(1));}} */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(2));}} */}
                {/* onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(3));}} */}
                    {/* <img id="desktopCase"  src={DC1} alt="image"/>
                    <img id="desktopCase"  src={DC2} alt="image"/>
                    <img id="desktopCase"  src={DC3} alt="image"/>
                    <img id="mobileCase"  width="100%" src={MDC1} alt="image"/>
                    <img id="mobileCase"  width="100%" src={MDC2} alt="image"/>
                    <img id="mobileCase"  width="100%" src={MDC3} alt="image"/>
                </div> */}
                <button id="openSuccessModal" style={{
                    boxShadow: (color == 'white') ? '0px 18px 43px rgba(184, 191, 210, 0.6)' : (color == 'green')? 'none' : 'none',
                    background: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600',
                    color: (color == 'white') ? '#ffffff' : (color == 'green')? '#ffffff' : '#101527'
                }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_get_in_touch"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block8[1]}</button>
            </div>
        </section>
        );
});

export { View };