import React from 'react';
import Slider from "react-slick";
import { settings2 } from '../../constants/slider';
import './style.scss';

import slide1 from '../../assets/img/slideImgClient1.png';
import slide2 from '../../assets/img/slideImgClient2.png';
import slide3 from '../../assets/img/slideImgClient3.png';
import slide4 from '../../assets/img/slideImgClient4.png';
import slide5 from '../../assets/img/slideImgClient5.png';
import slide6 from '../../assets/img/slideImgClient6.png';
import slide7 from '../../assets/img/slideImgClient7.png';

const View = React.memo(() => {
    return(
    <section className='clients'>
        <div className="wrapper">
            <h2 style={{paddingLeft: '10%'}}>
                Our clients love working with us
            </h2>
            <Slider className="not-tilda-slider" {...settings2}>
                <img src={slide1} alt='img' />
                <img src={slide2} alt='img' />
                <img src={slide3} alt='img' />
                <img src={slide4} alt='img' />
                <img src={slide5} alt='img' />
                <img src={slide6} alt='img' />
                <img src={slide7} alt='img' />
            </Slider>
        </div>
    </section>
    );
});

export { View };