import React from "react";
import Slider from "react-slick";
import { LayoutBase } from '../../layouts';
import { FeedbackForm, ModalHeader } from '../../blocks';
import { settings } from "../../constants/slider";
import point from '../../assets/img/blue_point.png';
import slide1 from '../../assets/img/screen3_1.png';
import slide2 from '../../assets/img/screen3_2.png';
import slide3 from '../../assets/img/screen3_3.png';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';

import mockup from '../../assets/img/mockup.svg';

import data from '../../assets/data.json';

const View = React.memo( () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
    const dispatch = useDispatch();
    return(
        <div style={{
            background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)'
        }}>
        <LayoutBase>
            <main className="EG">
                <div className="wrapper">
                    <div className="leftPart">
                        <h2>Gamification learning</h2>
                        <p>{data.lang[lang - 1].learning[0]}</p>
                        <ul>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p>
                                {data.lang[lang - 1].learning[1]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p>
                                {data.lang[lang - 1].learning[2]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p>
                                {data.lang[lang - 1].learning[3]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p>
                                {data.lang[lang - 1].learning[4]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p>
                                {data.lang[lang - 1].learning[5]}
                                </p>
                            </li>
                        </ul>
                        <button id="demoThree" onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_learning"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].learning[6]}</button>
                    </div>
                    <div className="rightPart">
                        <div className="mockUp" style={{backgroundImage: 'url(' + mockup + ')'}}></div>
                        <Slider className="iPhone_mock" {...settings} style={{overflow: 'hidden'}}>
                            <img src={slide1} width="256px" height="500px" alt="image"/>
                            <img src={slide2} width="256px" height="500px" alt="image"/>
                            <img src={slide3} width="256px" height="500px" alt="image"/>
                        </Slider>
                    </div>
                </div>
            </main>
        </LayoutBase>
        <FeedbackForm/>
        <ModalHeader/>
        </div>
        );
});

export { View };