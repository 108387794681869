import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import arrow from '../../assets/img/arrowB.svg';
import close from '../../assets/img/closeFeedback.svg';

import './style.scss';

const View = React.memo(() => {

    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
    localStorage.setItem('fromWhereAmI', fromWhereAmI);

    // const [valueTel, setValueTel] = useState('');
    // const [valueName, setValueName] = useState('');
    // const [valueSelect, setValueSelect] = useState('Whatsapp');

    const [visible, setVisible] = useState( false );
    
    // const handleChangeTel = event => {
    //     const result = event.target.value.replace(/[^0-9+]/g, '');
    //     setValueTel(result);
    // };
    // const handleChangeName = event => {
    //     setValueName(event.target.value);
    // };
    // const handleChangeSelect = event => {
    //     setValueSelect(event.target.value);
    // };

    useEffect(() => {

        let submit = document.getElementById('submit');
        let alertContainer = document.getElementById('alertContainer');

        function filterNonDigits(evt) {
            let event = evt || window.event;
            let val = event.target.value;
            let filtered = val.replace(/[^0-9+]/g, '');
            
            if(filtered !== val) {
                event.target.value = filtered;
                event.target.className += " error";
            }
        }

        document.getElementById('telephoneMI').addEventListener('input', (e) => {
            filterNonDigits(e);
        });

        function createAlert(text) {
            alertContainer.innerHTML = '<p>' + text + '</p>';
        }

        function deleteAlert() {
            alertContainer.innerHTML = '';
        }

        submit.addEventListener('click' || 'ontouchend', function() {
            deleteAlert();
            
            let nameMI = document.getElementById('nameMI').value;
            let tlph = document.getElementById('telephoneMI').value;
            let msgr = document.getElementById('messengerMI').value;
            if (document.getElementById('pp2').checked) {
                if (tlph.length <= 0 && nameMI.length <= 0) {
                    createAlert('Please, enter your telephone and your name');
                } else {
                    if (tlph.length < 10) {
                        createAlert('Please, enter correct telephone');
                    } else {
                        if (tlph.indexOf('+') > 0 || Object.values(tlph).filter(x => x==='+').length > 1) { 
                            createAlert('Please, enter correct telephone');
                        }
                        else {
                            if (nameMI.length < 2) {
                                createAlert('Please, enter correct name');
                            } else {
                                    SendMail2(nameMI, 
                                    "Form without company", 
                                    "Form without email", 
                                    fromWhereAmI, 
                                    'Choosen messenger: ' + msgr + 
                                    '<br/>' +
                                    'Telephone: ' + tlph, 
                                    'en-EN', 
                                    window.location.href);

                            }
                        }
                    }
                }
            } else {
                createAlert('Please, accept Privacy & Cookie Policy');
            }
            
        });

        function SendMail2(name, company, email, from, question, lang, page) {

            var dopInfo = '<br><b>Client data:</b><br>lang: ' + lang + '<br>page: ' + page + 
            '<br>ClientID: ' + localStorage.getItem('ClientID') +
            '<br>IsMobile: ' + localStorage.getItem('IsMobile') +
            '<br>FirstDateContact: ' + localStorage.getItem('FirstDateContact') +
            '<br>LastDateContact: ' + localStorage.getItem('LastDateContact') +
            '<br>FirstReferralSource: ' + localStorage.getItem('FirstReferralSource') +
            '<br>LastReferralSource: ' + localStorage.getItem('LastReferralSource') +
            '<br>ReferralUrl: ' + localStorage.getItem('ReferralUrl') +
            '<br>utm_source: ' + localStorage.getItem('utm_source') +
            '<br>utm_medium: ' + localStorage.getItem('utm_medium') +
            '<br>utm_campaign: ' + localStorage.getItem('utm_campaign') +
            '<br>PageNumber: ' + localStorage.getItem('PageNumber');

            var xhr = new XMLHttpRequest();
            xhr.open("POST", 'https://easygrow.digitum.nl/EasyGrow/SendMail?name=' + name + '&from=' + localStorage.getItem('fromWhereAmI') + '&email=' + email, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            var roflanEbalo = question + '<br>' + dopInfo;
            xhr.send('"' + roflanEbalo + '"');

            document.getElementById('nameMI').value = '';
            document.getElementById('telephoneMI').value = '';

            window.history.pushState({}, null, 'thank_you_submit');
            setVisible(true);
            
        }

        function clearHistory() {
            window.history.back();
        }

        document.getElementById('closeFeedback22').addEventListener('click' || 'ontouchend', () => {clearHistory()});
        document.getElementById('closeFeedback23').addEventListener('click' || 'ontouchend', () => {clearHistory()});

        console.log('i fire once');
    }, []);

    return (
        <>
        <section className='moreInfo'>
            <div className="wrapper">
                <h2>Need more info?</h2>
                <p className='afterTitle'>Let's have a chat in your messenger</p>
                <div className="inputsers">
                    <input id='telephoneMI' type="text" 
                    // value={valueTel} 
                    // onChange={handleChangeTel} 
                    maxlength="13" placeholder='Telephone'/>
                    <select id='messengerMI' name="select" 
                    // value={valueSelect} 
                    // onChange={handleChangeSelect}
                    >
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Telegram">Telegram</option>
                        <option value="Viber">Viber</option>
                    </select>
                    <input id='nameMI' type="text" 
                    // value={valueName} 
                    // onChange={handleChangeName} 
                    placeholder='Name' />
                    
                    <button className='ppS' id='submit'>Submit</button>
                </div>
                
                <p className="newFixes nF2" style={{ color: '#101527', textAlign: 'left', paddingLeft: '20px', paddingBottom: '0px', paddingTop: '16px' }}>
                    <label className="container" style={{marginRight: '10px'}}>
                        <input id='pp2' type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                    I accept <a href="./privacy.html" style={{color: '#101527', marginLeft: '5px', marginRight: '5px'}} target="_blank">Privacy Policy</a> & <a href="./cookie.html" style={{color: '#101527', marginLeft: '5px', marginRight: '5px'}} target="_blank">Cookie Policy</a>
                    <p className="alert"></p>
                </p>

                <div className="alertBlock" id='alertContainer'></div>
            </div>

        </section>
        <div className="modalThanks" style={{ display: (visible) ? 'block' : 'none', zIndex: '2' }}>
            <div className="modalThanksWrapper" style={{ display: 'block' }}>
                <img id="closeFeedback23" src={close} className='closeThanks' alt="icon" onClick={() => {
                    setVisible(false);
                }}/>
                <p id="closeFeedback22" className='mobCloseThanks' onClick={() => {
                    setVisible(false);
                }}>
                    <img src={arrow} alt="exit"/>
                    <span>Back</span>
                </p>
                <h2 style={{textAlign: 'center', paddingTop: '100px'}}>
                    <span>Thank you!</span>
                </h2>
                <p style={{fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: '300', fontSize: '17px', lineHeight: '150%',textAlign: 'center', paddingBottom: '100px', color: 'rgba(16, 21, 39, 0.5)', paddingLeft: '25px',paddingRight: '25px'}}>
                    Nice to meet you! We are processing your request.
                </p>
            </div>
        </div> 
    </>
    );
});

export { View };