import React from "react";
import { useDispatch } from 'react-redux';
import { setColorSite } from '../../../actions/actions';

const View = React.memo( () => {
    const dispatch = useDispatch();
    return(
        <svg id='white_tilo' onClick={() => dispatch(setColorSite('white'))} width="166" height="111" viewBox="0 0 166 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='white_right_wing' d="M164.898 49.2961C165.799 51.9141 157.991 52.514 152.418 50.7029C153.33 51.9081 157.502 53.1835 156.881 54.4544C156.261 55.7254 152.836 55.4088 150.551 54.2338C148.358 53.0827 147.054 52.02 145.384 50.2389C147.015 52.2465 149.713 55.3115 148.325 55.801C146.938 56.2904 143.28 54.349 139.428 48.2992C139.641 49.4632 140.567 51.6086 139.305 52.3309C138.043 53.0531 136.032 48.9271 136 46.4243C135.996 40.625 142.253 36.9521 147.299 39.8838C149.893 41.3944 152.348 43.1459 154.869 44.7646C159.444 47.6927 164.563 47.5806 164.898 49.2961Z" fill="url(#paint0_linear)"/>
            <path id='white_left_wing' d="M1.07053 47.7294C-0.0612631 50.2562 7.66199 51.5528 13.3749 50.2479C12.3581 51.3666 8.0892 52.2633 8.59306 53.5847C9.09691 54.9061 12.537 54.8974 14.9178 53.9317C17.2056 52.9816 18.5987 52.0398 20.422 50.4155C18.6177 52.269 15.6563 55.0802 16.9944 55.6919C18.3326 56.3035 22.1491 54.6974 26.5272 49.0167C26.2109 50.1571 25.0965 52.2109 26.2888 53.0432C27.481 53.8756 29.8534 49.9462 30.1092 47.4562C30.6333 41.6807 24.7299 37.4624 19.4414 39.9305C16.7228 41.2028 14.1203 42.7274 11.4651 44.1139C6.64644 46.6207 1.55816 46.0508 1.07053 47.7294Z" fill="url(#paint1_linear)"/>
            <ellipse id='wred' cx="83.9996" cy="55.5" rx="54" ry="55" fill="red"/>
            <ellipse id='wgreen' cx="83.9996" cy="55.5" rx="54" ry="55" fill="green"/>
            <ellipse cx="83.9996" cy="55.5" rx="55" ry="55.5" fill="url(#paint2_linear)"/>
            <path fill-rule="evenodd" style={{transform: 'scale(1.5)', transformOrigin: '12px 35px'}} clip-rule="evenodd" d="M55.7632 32H56.9652C58.8775 32 60.5532 32.9805 61.4852 34.4506C66.3596 35.2119 70.4037 38.3743 72.2814 42.6443C70.4841 48.2443 65.0893 52.3107 58.7135 52.3107C57.4393 52.3107 56.2043 52.1483 55.0294 51.8437C57.4259 53.6292 60.4275 54.6909 63.6854 54.6909C67.249 54.6909 70.5062 53.4205 73 51.322C71.7149 56.3804 67.5388 60.338 62.2648 61.4587V64H61.0629C59.1446 64 57.4645 63.0134 56.5342 61.5358C49.9657 60.3259 45 54.7376 45 48.0264C45 43.8813 46.8943 40.1646 49.8906 37.6433L49.8906 37.6433C51.5538 36.2437 53.5565 35.2125 55.7632 34.6809V32Z" fill="#101527"/>
            {/* <path d="M106.588 54.9865C106.594 59.9682 104.954 64.8086 101.922 68.7569C98.8901 72.7053 94.6355 75.541 89.8182 76.8243C85.0009 78.1076 79.8902 77.7667 75.2786 75.8545C70.6671 73.9423 66.8124 70.5656 64.3125 66.2483C61.8126 61.9309 60.8072 56.914 61.4521 51.9758C62.0971 47.0376 64.3564 42.454 67.8796 38.9359C71.4029 35.4178 75.9932 33.1618 80.9386 32.5178C85.884 31.8738 90.9081 32.8778 95.2318 35.374L83.925 54.958L106.588 54.9865Z" fill="#101527"/> */}
            <defs>
                <linearGradient id="paint0_linear" x1="162.525" y1="42.3757" x2="137.227" y2="51.0826" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2EDF3"/>
                    <stop offset="1" stopColor="#CFD9DE"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="4.05324" y1="41.0492" x2="28.4707" y2="51.986" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2EDF3"/>
                    <stop offset="1" stopColor="#CFD9DE"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="63.89" y1="40.5461" x2="94.4432" y2="73.9862" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#E0EAED"/>
                </linearGradient>
            </defs>
        </svg>
    );
});

export { View };